import React, { PureComponent } from 'react';
import Helmet from 'react-helmet';
import { Layout } from 'containers';
import { Banner } from 'components';
import { Link } from 'gatsby';
import { BASE_URL, SOCIAL } from 'config';

class ArohaAotearoa extends PureComponent {
  render() {
    const social = { ...SOCIAL };
    ['YOUTUBE', 'TWITTER'].forEach(e => delete social[e]);
    const items = [
      {
        url: '/adventure-tours/two-weeks',
        imageUrl: 'https://hakatours-staging.imgix.net/000/MicrosoftTeams-image_(28).png?w=450&auto=format',
      },
      {
        url: '/new-zealand-winter-tours/northern-wonders',
        imageUrl: 'https://hakatours-staging.imgix.net/000/MicrosoftTeams-image_(29).png?w=450&auto=format',
      },
      {
        url: '/haka-plus-tours/20-day',
        imageUrl: 'https://hakatours-staging.imgix.net/000/MicrosoftTeams-image_(27).png?w=450&auto=format',
      },
      {
        url: '/adventure-tours/north-island',
        imageUrl: 'https://hakatours-staging.imgix.net/000/MicrosoftTeams-image_(25).png?w=450&auto=format',
      },
      {
        url: '/new-zealand-winter-tours/winter-wonders',
        imageUrl: 'https://hakatours-staging.imgix.net/000/MicrosoftTeams-image_(26).png?w=450&auto=format',
      },
      {
        url: '/haka-plus-tours/10-day-south',
        imageUrl: 'https://hakatours-staging.imgix.net/000/MicrosoftTeams-image_(30).png?w=450&auto=format',
      },
    ];

    return (
      <Layout page="winter-sale">
        <Helmet
          title="Winter Sale - save up to 20%!"
          meta={[
            {
              name: 'description', content: 'Experience New Zealand during winter and save up to 20% off your Haka Tours.',
            },
            { name: 'og:title', content: 'Winter Sale - save up to 20%!' },
            {
              name: 'og:description', content: 'Experience New Zealand during winter and save up to 20% off your Haka Tours.',
            },
          ]}
          link={[{ rel: 'canonical', href: `${BASE_URL}/winter-sale/` }]}
        />

        <Banner modifier="overlay-40" backgroundImage="https://hakatours-staging.imgix.net/5afcfd73369dcd00010000f1/33.UncoveredNZ_Day17_FranzJosef_original.jpg" backgroundPosition="50% 60%">
          <h1 className="c-heading c-heading--h1 winter-sale-banner" style={{ marginTop: '4rem' }}>Winter Sale</h1>
          <h2 className="c-heading c-heading--h2 winter-sale-banner">save up to 20%!</h2>
        </Banner>

        <section className="l-section">
          <div className="l-container l-container--small u-text--center">
            <h2 className="c-heading c-heading--h2">Experience New Zealand this winter and save up to 20% off your Haka Tour.</h2>
            <p>
              Travelling over this period offers numerous advantages including fewer crowds, a more comfortable climate (great for those of you
              looking to escape the Northern Hemisphere heat!), and stunning scenery – think snow-capped mountains and crisp blue skies.
              Discover more on <a href="https://hakatours.com/blog/top-reasons-to-visit-new-zealand-in-the-shoulder-seasons/" target="_blank" rel="noreferrer">our blog</a>.
            </p>
          </div>
          <div className="l-container u-text--center">
            <h2 className="c-heading c-heading--h3 mt-4">Here are a handful of our favourite tours - which will you choose?</h2>
            <div className="l-tour-list__grid mt-4" style={{ rowGap: '5rem' }}>
              {items.map(item => (
                <div
                  key={item.id}
                  className="grid-item"
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    window.location.href = item.url;
                  }}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter' || event.key === ' ') {
                      window.location.href = item.url;
                    }
                  }}
                >
                  <img src={item.imageUrl} alt="" />
                  <button type="button" className="c-button c-button--primary mt-1">
                    BOOK NOW
                  </button>
                </div>
              ))}
            </div>
          </div>
          <div className="l-container l-container--small u-text--center mt-5">
            <h4 className="l-alt-panels__item-title c-heading c-heading--h4">*Terms and Conditions</h4>
            <div className="fs-small">
              <p>
                <i>
                Winter Sale Discounts valid on new bookings only made from 05 March – 07 April 2024. 
                Offer valid on Haka Adventure, Haka Plus and Haka Winter (excludes Haka Snow Safari) - for travel 10 April – 31 August 2024. 
                Departure date subject to availability at time of booking. 
                Tour savings of up to 20% per booking. 
                Under no circumstances will the discounts be applied to existing bookings. 
                Full payment is required at least 60 days prior to travel. 
                The discounts cannot be combined or used in conjunction with any other offers. 
                Discounts are subject to availability; may be withdrawn at any point without notice. 
                Does not apply to pre- or post-tour accommodation, insurance, international airfare not specified in the itinerary, 
                upgrades, add-ons, equipment rental, transfers, theme packs, or other in-country or on-board services. 
                For full general T&Cs visit <Link to="/booking-conditions/">Haka Tours Ltd Terms and Conditions</Link>
                </i>
              </p>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

export default ArohaAotearoa;
